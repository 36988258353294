'use client';

import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import { Montserrat } from 'next/font/google';
import { captureException, flush } from '@/services/sentry';

import { getStore } from '@/utils/getStoreId';
import ErrorPage from '@/components/templates/ErrorPage';

const isCity = process.env.NEXT_PUBLIC_STORE_ID === 'city';

const montserrat = Montserrat({
  weight: ['100', '300', '400', '600', '700', '800'],
  style: ['normal'],
  subsets: ['latin'],
  display: 'swap'
});

const redirectToErrorPage = async ({ error, url, router }) => {
  await captureException(
    error || new Error(`Error del lado del servidor de Next en ${url}`)
  );
  if (error) {
    await flush(2000);
  }
  let storeId = `/${getStore(url)}`;
  if (storeId === `/${process.env.NEXT_PUBLIC_STORE_ID}`) {
    storeId = '';
  }
  if (typeof window !== 'undefined') {
    window.location.href = `${storeId}/ocurrio-un-error`;
  } else {
    router.push(`${storeId}/ocurrio-un-error`);
  }
};

const errorPage = ({ error }) => {
  const url = usePathname();
  const router = useRouter();
  useEffect(() => {
    redirectToErrorPage({ error, url, router });
  }, []);

  const FACEBOOK_VERIFICATION_ID =
    process.env.NEXT_PUBLIC_FACEBOOK_VERIFICATION_ID;
  const GOOGLE_VERIFICATION_ID = process.env.NEXT_PUBLIC_GOOGLE_VERIFICATION_ID;

  return (
    <html lang="es">
      <head>
        <link
          rel="icon"
          href={`/favicon-${isCity ? 'city' : 'vaypol'}.ico`}
        />
        <meta
          name="robots"
          content="noindex,nofollow"
        />
        <meta
          name="version"
          content={process.env.NEXT_PUBLIC_APP_VERSION}
        />
        {FACEBOOK_VERIFICATION_ID && (
          <meta
            name="facebook-domain-verification"
            content={FACEBOOK_VERIFICATION_ID}
          />
        )}
        {GOOGLE_VERIFICATION_ID && (
          <meta
            name="google-site-verification"
            content={GOOGLE_VERIFICATION_ID}
          />
        )}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </head>
      <body className={montserrat.className}>
        <ErrorPage />
      </body>
    </html>
  );
};
errorPage.propTypes = {
  error: PropTypes.object,
  reset: PropTypes.func
};
export default errorPage;
