'use client';

import PropTypes from 'prop-types';
import { HeaderLite, Footer, PaymentResume } from '@/components';
import Header from '@/components/organisms/Header';
import { MaxContainer } from '@/components/atoms';
import styles from './layout.module.scss';

const Layout = (props) => {
  const {
    children,
    type = 'complete',
    isHomePage = false,
    isGalleryPage = false,
    h1InFooter = '',
    withoutNavbar = false,
    withoutBrandsSlider = false,
    withoutNewsletter = false,
    withoutSearchbar = false,
    withoutCP = false
  } = props;

  return (
    <>
      {type === 'complete' && (
        <Header
          withoutNavbar={withoutNavbar}
          withoutBrandsSlider={withoutBrandsSlider}
          withoutSearchbar={withoutSearchbar}
          withoutCP={withoutCP}
          isGalleryPage={isGalleryPage}
        />
      )}
      {(type === 'lite' || type === 'onlyBrand') && <HeaderLite />}
      <MaxContainer>
        <div className={styles.container}>
          {type === 'onlyBrand' && children}
          {type === 'lite' && <PaymentResume>{children}</PaymentResume>}
        </div>
        {type === 'complete' && children}
        {type === 'complete' && (
          <Footer
            h1InFooter={h1InFooter}
            isHomePage={isHomePage}
            withoutNewsletter={withoutNewsletter}
          />
        )}
      </MaxContainer>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['complete', 'lite', 'onlyBrand']),
  isHomePage: PropTypes.bool,
  isGalleryPage: PropTypes.bool,
  h1InFooter: PropTypes.string,
  withoutNavbar: PropTypes.bool,
  withoutBrandsSlider: PropTypes.bool,
  withoutSearchbar: PropTypes.bool,
  withoutCP: PropTypes.bool,
  withoutNewsletter: PropTypes.bool
};

export default Layout;
