import { AiOutlineAlert } from 'react-icons/ai';
// import PropTypes from 'prop-types';
import Layout from '@/components/Layout';
import MaxContainer from '@/components/atoms/MaxContainer';
import Text from '@/components/atoms/Text';
import styles from './index.module.scss';

const ErrorPage = () => {
  return (
    <Layout>
      <MaxContainer>
        <div className={styles.container}>
          <div className={styles.content}>
            <AiOutlineAlert
              fontSize={30}
              color="white"
            />
            <Text textColor="white">
              Ocurrió un error por favor comuníquese con 0810 666 0945
            </Text>
          </div>
        </div>
      </MaxContainer>
    </Layout>
  );
};

export default ErrorPage;
